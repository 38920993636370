<script setup lang="ts">
</script>
<template>
  <div
    class="our-clients"
    id="our-clients"
  >
    <div class="our-clients-content">
      <h2 class="our-clients-content-title">Наши клиенты</h2>
      <ImageSlider />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.our-clients-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: get-vw(1150px);
  margin-left: auto;
  margin-right: auto;
}
.our-clients {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: get-vw(150px);

    &-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: get-vw(41px);
      text-align: center;
      color: #000000;
    }
  }
}
</style>
